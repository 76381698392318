
import axios from 'axios';
export default {
    
    async createAdmins(params)  {
        return await axios.post(`admins/create.php` , params)
    },
    async getAllAdmins()  {
        return await axios.get(`admins/readAll.php`)
    },
    async getOneAdmins(admin_id)  {
        return await axios.get(`admins/readById.php?admin_id=${admin_id}`)
    },
    async getAdminsByColumn(column , value)  {
        return await axios.get(`admins/readByColumn.php?column=${column}&value=${value}`)
    },
    async deleteAdmins(admin_id)  {
        return await axios.post(`admins/delete.php?admin_id=${admin_id}`)
    },
    async updateAdmins(admin_id , params)  {
        return await axios.post(`admins/update.php?admin_id=${admin_id}` , params)
    }
}