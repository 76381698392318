
<template>
    <div>
            <v-container class="my-2">
                    <v-layout row wrap px-0 mb-4>
                        <v-flex xs9>
                            <v-breadcrumbs class="px-0" :items="$store.getters.language.data.admins.list_path">
                                <template v-slot:item="{ item }">
                                    <v-breadcrumbs-item :to="item.path" :disabled="item.disabled">
                                        {{ item.title.toUpperCase() }}
                                    </v-breadcrumbs-item>
                                </template>
                            </v-breadcrumbs>
                        </v-flex>
                        <v-flex xs3>
                           
                        </v-flex>
                    </v-layout>
                <form @submit.prevent="addAdmins" autocomplete="off">
                    <v-layout row wrap>
        
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="admins.firebase_uid" type="text" :label="$store.getters.language.data.admins.firebase_uid" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="admins.admin_name" type="text" :label="$store.getters.language.data.admins.admin_name" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                        <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="['admin','user']" v-model="admins.admin_role" dense  filled outlined  item-text="admin_role"
                                    item-value="admin_role" :return-object="false" :label="$store.getters.language.data.admins.admin_role">
                                </v-select>
                        </v-flex>
                
                        <v-flex xs12 lg2 xl2 md2 sm4>
                            <v-btn  color="primary" :loading="loading_btn" type="submit" >{{$store.getters.language.data.admins.add_btn}}</v-btn>
                        </v-flex>
                    </v-layout>
                </form>
<v-layout row wrap mt-5>
    <v-flex xs12>
        <v-card flat>
            <v-card-text>
                <v-data-table :loading="loading" :headers="headers"    :search="search" :items="rows" class="elevation-0"
                    item-key="admin_id">
                    <template v-slot:[`item.admin_id`]="{ item }">
                        <div>
                            <v-btn icon :to="'/admins-list/'+item.admin_id"  color="teal" class="mx-1">
                                <v-icon> mdi-pencil-outline </v-icon>
                            </v-btn>
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="error" icon class="mx-1" v-bind="attrs" v-on="on" >
                                        <v-icon> mdi-delete-outline </v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-text>
                                        {{$store.getters.language.data.admins.delete_question}}
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn text class="elevation-0">
                                            {{$store.getters.language.data.admins.cancel_btn}}
                                        </v-btn>
                                        <v-btn color="error" class="elevation-0" @click="deleteAdmins(item.admin_id)">
                                            {{$store.getters.language.data.admins.yes_btn}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-menu>
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
            
        </v-card>
    </v-flex>
</v-layout>
</v-container>

<v-snackbar v-model="snackbar.value" :color="snackbar.color">
    {{snackbar.text}}
</v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/admins.request.js'
    import * as xlsx from 'xlsx/xlsx.js';
    export default {
        data() {
            return {
                admins: {},
                search: '',
                loading : true,
                loading_btn:false,
                
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                rows:[],
                
                selected_admins : {},
                headers: [

                    
                        { 
                            text: this.$store.getters.language.data.admins.firebase_uid,
                            align: 'start',
                            sortable: true,
                            value: 'firebase_uid',
                        },
                        { 
                            text: this.$store.getters.language.data.admins.admin_name,
                            align: 'start',
                            sortable: true,
                            value: 'admin_name',
                        },
                        { 
                            text: this.$store.getters.language.data.admins.admin_role,
                            align: 'start',
                            sortable: true,
                            value: 'admin_role',
                        },   {
            text: this.$store.getters.language.data.actions,
            align: 'start',
            sortable: true,
            value: 'admin_id',
        }
                ],
            }
        },
        computed: {
            page(){
                const pageKey = 'ADMINS'
                return {}
            }
        },
        mounted(){
            this.readAdmins();
        },
        methods: {
            exportExcel(){
                const list = this.rows
                let result = []
                for (let i = 0; i < list.length; i++) {
                    const item = list[i];
                    let obj = {admin_id : item.admin_id,firebase_uid : item.firebase_uid,admin_name : item.admin_name,admin_role : item.admin_role,
                    }
                    result.push(obj)
                }
				var f = xlsx.utils.json_to_sheet(result)
				var wb = xlsx.utils.book_new()
				xlsx.utils.book_append_sheet(wb, f, 'sheet')
				xlsx.writeFile(wb, result.length+' rows of '+'Admins.xlsx')
            },
            addAdmins() {
                this.loading_btn = true
                requests.createAdmins(this.admins).then(r => {
                    if (r.status == 200) {
                        this.admins = {}
                        this.rows.push(
                            r.data.new_data
                        )
                        this.snackbar = {
                            value: true,
                            text: 'Admins Added',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to add Admins',
                            color: 'error'
                        }
                    }
                })
                .finally(() => {
                    this.loading_btn = false
                })

            },
            deleteAdmins(admin_id) {
                requests.deleteAdmins(admin_id).then(r => {
                    if (r.status == 200) {
                        this.rows = this.rows.filter(f =>{
                            return f.admin_id != admin_id
                        })
                        this.snackbar = {
                            value: true,
                            text: 'Admins Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            },
            
            readAdmins() {
                this.loading = true
                requests.getAllAdmins().then(r => {
                    if (r.status == 200) {
                        this.rows = r.data.admins
                        
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read Admins',
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Fail to read Admins',
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
            selectAdmins(i) {
                this.selected_admins = i
                this.delete_dialog = true
            },
        },
    }
</script>
                    